import React, {useEffect, useState} from "react";
import {useApi} from "../../../hooks/useApi";
import {useModal} from "../../../hooks/useModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faPenNib, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Perfil} from "../../../types/perfil";
import Loading from "../../../components/Loading";
import ContadorTextFiled from "../../../components/ContadorTextField";

function PerfilPage() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();
    const [perfilId, setPerfilId] = useState(0);
    const [perfil, setPerfil] = useState<Perfil>()

    const [descricao, setDescricao] = useState("");
    const [nomePerfil, setNomePerfil] = useState("");
    const [nivel, setNivel] = useState(0);
    const [perfis, setPerfis] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleReadAll();
    }, [])

    const handleReadAll = async () => {
        setLoading(true)
        let response = await api.perfil.readAll();
        setPerfis(response.data);
        setLoading(false)
    }

    const handleReadOne = async (e: any, perfil_id: number) => {
        e.preventDefault();
        let response = await api.perfil.readOne(perfil_id);
        setDescricao(response.data.descricao)
        setNivel(response.data.nivel)
        setNomePerfil(response.data.nome_perfil)
        setPerfilId(response.data.id)
    }
    const handleSubmitFormPerfil = async (e: any) => {
        try {
            e.preventDefault();
            const response = await api.perfil.insert(descricao, nivel, nomePerfil);
            //@ts-ignore
            if (response.status === 200) {
                modal.timer("Perfil", "Salvo com sucesso!", "success");
                handleReadAll();
            }
        } catch (error) {
            //@ts-ignore
            if (error.response.status === 422) {
                modal.timer("Perfil", "Perfil duplicado!", "warning");

            }
        }


    }

    const handleSubmitOne = async (e: any) => {
        e.preventDefault();

        const response = await api.perfil.update(perfilId, descricao, nomePerfil, nivel);
        if (response.status === 200) {
            modal.timer("Perfil", "Salvo com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Perfil", "Salvo com sucesso!", "success");
        } else {
            modal.timer("Perfil", "Nome do perfil duplicado", "warning")
        }
    }

    const handleDelete = async (e: any, perfil_id: number) => {
        e.preventDefault();

        const response = await api.perfil.delete(perfil_id);

        if (response.status === 200) {
            modal.timer("Perfil", "Deletado com sucesso!", "success");
            handleReadAll();
        }
        if (response.status === 422) {
            modal.timer("Perfil", "Nome do perfil duplicado", "warning")
        }
    }

    const handleClearInput = () => {
        setDescricao("")
        setNivel(0)
        setNomePerfil("")
        setPerfilId(0)
    }

    return (
        <div className="p-5">
            {loading && <Loading/>}
            <div>
                <h1>Perfil</h1>
                <hr/>
                <form onSubmit={perfilId === 0 ? (e) => handleSubmitFormPerfil(e) : (e) => handleSubmitOne(e)}>

                    <div className="row mb-4">
                        <div className="col">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="floatingInput"
                                       value={nomePerfil}
                                       maxLength={30}
                                       onChange={(e) => setNomePerfil(e.target.value)}
                                       placeholder="name@example.com" required/>
                                <label htmlFor="floatingInput">Nome do Perfil</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-floating">
                                <select id="floatingInput" value={nivel} className="form-select"
                                        onChange={(e) => setNivel(parseInt(e.target.value))}
                                >

                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </select>
                                <label htmlFor="floatingInput">Nível</label>
                            </div>
                        </div>
                    </div>

                    <div className="form-floating mb-4">
                        <textarea className="form-control" value={descricao}
                                  placeholder="Leave a comment here"
                                  id="floatingTextarea2"
                                  maxLength={100}
                                  required
                                  onChange={(e) => setDescricao(e.target.value)}
                        ></textarea>
                        <label htmlFor="floatingTextarea2">Descrição</label>
                        <ContadorTextFiled texto={descricao} tamanho={100}/>
                    </div>

                    <div className="row">

                        <div className="col-md-6">
                            <button type="submit"
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">{perfilId === 0 ? "Salvar" : 'Atualizar'}</button>
                        </div>
                        <div className="col-md-6">
                            <button type="button" onClick={handleClearInput}
                                    className="btn btn-verde btn-primary btn-block mt-4 w-100">Limpar
                            </button>
                        </div>
                    </div>

                </form>


            </div>
            <hr/>
            <div className="p-1 mt-2 mb-5 ">
                <div className='d-flex  justify-content-end w-100 align-content-end mb-3'>
                    <button className="btn btn-verde btn-primary " onClick={
                        handleReadAll
                    }><FontAwesomeIcon icon={faArrowsRotate}/>
                    </button>
                </div>
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Descrição</th>
                        <th className="text-center">Nível</th>
                        <th>Nome do Perfil</th>
                        <th className="text-center">Ações</th>
                    </tr>
                    </thead>
                    <tbody>

                    {perfis.map(({id, descricao, nivel, nome_perfil}) => (
                        <tr>
                            <td>{descricao}</td>
                            <td className="text-center">{nivel}</td>
                            <td>{nome_perfil}</td>
                            <td className="text-center"><FontAwesomeIcon onClick={(e) => handleReadOne(e, id)}
                                                                         icon={faPenNib}/> <FontAwesomeIcon
                                onClick={(e) => handleDelete(e, id)} icon={faTrash}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PerfilPage;