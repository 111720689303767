import React, {useContext, useEffect, useState} from "react";
import './documento.css'
import ListarDocumento from "./ListarDocumento";
import DocumentoForm from "./DocumentoForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faCirclePlus, faPlusMinus} from "@fortawesome/free-solid-svg-icons";
function DocumentoPage() {

    const [btnMaisMenos, setBtnMaisMenos] = useState(faCirclePlus);

    function handleBtnMaisMenos() {
        if(btnMaisMenos == faCirclePlus) {
            setBtnMaisMenos(faCircleMinus)
        } else{
            setBtnMaisMenos(faCirclePlus)
        }
    }
    return (
        <div className="p-5">
                <>
                    <div>
                        <div className='d-flex justify-content-lg-between align-items-center'>
                            <div><h1>Documento <FontAwesomeIcon style={{color: 'red'}} onClick={handleBtnMaisMenos} icon={btnMaisMenos} /></h1> </div>
                        </div>
                    </div>
                    <hr/>
                    {
                        btnMaisMenos === faCircleMinus &&
                        <DocumentoForm  />
                    }

                    <ListarDocumento />
                </>
        </div>
    )
}

export default DocumentoPage;