import docsmart_light_mode from "../assets/docsmart_logo.svg";
import docsmart_dark_mode from "../assets/docsmart_logo_dark_mode.svg";

import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../contexts/Auth/AuthContext";
export default function LogoCentral() {
    let context = useContext(AuthContext)

    const [isDarkTheme, setIsDarkTheme] = useState(context.themeMode)
    useEffect(() => {
        console.log(context.themeMode)
        setIsDarkTheme(context.themeMode)
    }, [context.themeMode]);

    return (
        <>
            {
                !isDarkTheme &&
                <img className="mb-4 img-fluid" src={docsmart_light_mode}  alt="Logo DocSmart" width={150}/>
            }
            {
                isDarkTheme &&
                <img className="mb-4 img-fluid" src={docsmart_dark_mode} alt="Logo DocSmart"  width={150}/>
            }

        </>

    )
}