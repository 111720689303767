import React, {useEffect, useState} from 'react';
import DocumentoForm from "./DocumentoForm";
import {useNavigate, useParams} from "react-router-dom";
import {useApi} from "../../hooks/useApi";
import {Documento} from "../../types/documento";
interface Params {
    documento_id?: string;
}
export default function AlterarDocumento() {
    let navigate = useNavigate();
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    const { documento_id }: Params = useParams();
    const [documento, setDocumento] = useState<Documento>()
    const [setores, setSetores] = useState([]);
    const [usuarios, setUsuarios] = useState([])

    useEffect(() => {
        //@ts-ignore
        handleReadOne(documento_id)
    }, [])
    async function handleReadOne(documento_id: string) {
        let response = await api.documento.readOne(documento_id);
        setDocumento(response.data.documento)
        setUsuarios(response.data.destinatarios.usuarios)
        setSetores(response.data.destinatarios.setores)
    }
    function handleVoltar(e: any) {
        e.preventDefault();
        navigate('/documento')
    }
    return(
        <div className="p-5">
            <div className='d-flex justify-content-lg-between align-items-center'>
                <div><h1>Alterar Documento</h1></div>

            </div>
            <hr/>
            <div className="mb-3">
            <span style={{marginRight: "20px"}} >
                        <button className="btn btn-primary btn-verde " onClick={
                            (e) => handleVoltar(e)
                        }>Voltar
                        </button>
                    </span>
            </div>
            <DocumentoForm documento = {documento} setores_param = {setores} usuarios_param = {usuarios} />
        </div>
    )
}