import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, Title, SubTitle} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {useApi} from "../../hooks/useApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faFilterCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {EssaDataNaoPodeSerMaiorQueEssa} from "../../services/DateValidate";
import {useModal} from "../../hooks/useModal";

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle);


export default function Grafico() {
    let token = localStorage.getItem('authToken')
    let api = useApi(token);
    let modal = useModal();
    const [dataInicio, setDataInicio] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [GraficoProcessoPorPassoEmAndamentoLabel, setGraficoProcessoPorPassoEmAndamentoLabel] = useState([])
    const [GraficoProcessoPorPassoEmAndamentoTotal, setGraficoProcessoPorPassoEmAndamentoTotal] = useState([])
    const [GraficoDocumentoPorTipoLabel, setGraficoDocumentoPorTipoLabel] = useState([])
    const [GraficoDocumentoPorTipoTotal, setGraficoDocumentoPorTipoTotal] = useState([])
    const [GraficoDocumentoPorSetorLabel, setGraficoDocumentoPorSetorLabel] = useState([])
    const [GraficoDocumentoPorSetorTotal, setGraficoDocumentoPorSetorTotal] = useState([])
    const [GraficoDocumentoPorPassoEmAndamentoLabel, setGraficoDocumentoPorPassoEmAndamentoLabel] = useState([])
    const [GraficoDocumentoPorPassoEmAndamentoTotal, setGraficoDocumentoPorPassoEmAndamentoTotal] = useState([])
    useEffect(() => {
        handleGraficoDocumentoPorTipo()
        handleGraficoDocumentoPorSetor()
        handleGraficoDocumentoPorPassoEmAndamento()
            handleGraficoProcessoPassoEmAndamento()
    }, [])

    function handleSubmit(e: any) {
        e.preventDefault()
        handleGraficoDocumentoPorTipo()
        handleGraficoDocumentoPorSetor()
        handleGraficoDocumentoPorPassoEmAndamento()
        handleGraficoProcessoPassoEmAndamento()
    }

    useEffect(() => {
        if (!EssaDataNaoPodeSerMaiorQueEssa(dataInicio, dataFinal)) {
            modal.semTimerWarning("Filtro data", "A data início não pode ser posterior que a data final");
            setDataInicio("")
            setDataFinal("")
        }

    }, [dataInicio, dataFinal])
    const GraficoProcessoPorPassoEmAndamento = {
        labels: GraficoProcessoPorPassoEmAndamentoLabel,
        datasets: [
            {
                label: '# de documentos',
                data: GraficoProcessoPorPassoEmAndamentoTotal,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
    const GraficoDocumentoPorSetor = {
        labels: GraficoDocumentoPorSetorLabel,
        datasets: [
            {
                label: '# de documentos',
                data: GraficoDocumentoPorSetorTotal,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
    const GraficoDocumentoPorPassoEmAndamento = {
        labels: GraficoDocumentoPorPassoEmAndamentoLabel,
        datasets: [
            {
                label: '# de documentos',
                data: GraficoDocumentoPorPassoEmAndamentoTotal,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
    const GraficoDocumentoPorTipo = {
        labels: GraficoDocumentoPorTipoLabel,
        datasets: [
            {
                label: '# de documentos',
                data: GraficoDocumentoPorTipoTotal,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    async function handleGraficoProcessoPassoEmAndamento() {

        let response = await api.grafico.GraficoProcessoPorPassoEmAndamento(dataInicio, dataFinal);
        if (response.data.label != null) {
            setGraficoProcessoPorPassoEmAndamentoLabel(response.data.label)
            setGraficoProcessoPorPassoEmAndamentoTotal(response.data.total)
        } else {
            setGraficoProcessoPorPassoEmAndamentoLabel([])
            setGraficoProcessoPorPassoEmAndamentoTotal([])
        }

    }


    async function handleGraficoDocumentoPorTipo() {

        let response = await api.grafico.GraficoDocumentoPorTipo(dataInicio, dataFinal);
        if (response.data.label != null) {
            setGraficoDocumentoPorTipoLabel(response.data.label)
            setGraficoDocumentoPorTipoTotal(response.data.total)
        } else {
            setGraficoDocumentoPorTipoLabel([])
            setGraficoDocumentoPorTipoTotal([])
        }

    }

    async function handleGraficoDocumentoPorSetor() {

        let response = await api.grafico.GraficoDocumentoPorSetor(dataInicio, dataFinal);
        if (response.data.label != null) {
            setGraficoDocumentoPorSetorLabel(response.data.label)
            setGraficoDocumentoPorSetorTotal(response.data.total)
        } else {
            setGraficoDocumentoPorSetorLabel([])
            setGraficoDocumentoPorSetorTotal([])
        }
    }

    async function handleGraficoDocumentoPorPassoEmAndamento() {

        let response = await api.grafico.GraficoDocumentoPorPassoEmAndamento(dataInicio, dataFinal);
        if (response.data.label != null) {
            setGraficoDocumentoPorPassoEmAndamentoLabel(response.data.label)
            setGraficoDocumentoPorPassoEmAndamentoTotal(response.data.total)
        } else {
            setGraficoDocumentoPorPassoEmAndamentoLabel([])
            setGraficoDocumentoPorPassoEmAndamentoTotal([])
        }
    }
    return (
        <>
            <div className="p-5">
                <div><h1>Gráficos</h1></div>
                <hr/>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="row">
                        <div className="col">
                            <div className="form-floating">
                                <input type="date" className="form-control" id="floatingInput"
                                       value={dataInicio}
                                       onChange={(e) => setDataInicio(e.target.value)}
                                       placeholder="data" required/>
                                <label htmlFor="floatingInput">Data/Início</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-floating">
                                <input type="date" className="form-control" id="floatingInput"
                                       value={dataFinal}
                                       onChange={(e) => setDataFinal(e.target.value)}
                                       placeholder="data" required/>
                                <label htmlFor="floatingInput">Data/Final</label>
                            </div>
                        </div>

                        <div className="col-xs-6 col-md-1 ">
                            <div>
                                <button className="btn btn-verde btn-primary w-100" type="submit"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Filtrar"><FontAwesomeIcon icon={faFilter}/>
                                </button>
                            </div>

                        </div>
                        <div className="col-xs-6 col-md-1 1">
                            <div>
                                <button className="btn btn-verde btn-primary w-100" type="reset"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Limpar Filtro"><FontAwesomeIcon
                                    icon={faFilterCircleXmark}/>
                                </button>
                            </div>

                        </div>

                    </div>
                </form>
                <hr/>
                <div className="row">
                    <div className="col-3">
                        <Doughnut data={GraficoDocumentoPorTipo}

                                  options={{
                                      plugins: {
                                          legend: {
                                              display: true,
                                          },
                                          title: {
                                              display: true,
                                              text: 'Documentos/Tipo Documento',
                                          },
                                          subtitle: {
                                              display: true,
                                              text: 'Número de Documentos por Tipo de Documentos'
                                          },

                                      },

                                  }}/>
                    </div>
                    <div className="col-3">
                        <Doughnut data={GraficoDocumentoPorSetor}

                                  options={{
                                      plugins: {
                                          legend: {
                                              display: true,
                                          },
                                          title: {
                                              display: true,
                                              text: 'Documentos/Setor',
                                          },
                                          subtitle: {
                                              display: true,
                                              text: 'Número de Documentos por Setor'
                                          },
                                      },

                                  }}/>
                    </div>
                    <div className="col-3">
                        <Doughnut data={GraficoDocumentoPorPassoEmAndamento}

                                  options={{
                                      plugins: {
                                          legend: {
                                              display: true,
                                          },
                                          title: {
                                              display: true,
                                              text: 'Documentos/PassoAndamento',
                                          },
                                          subtitle: {
                                              display: true,
                                              text: 'Número de Documentos por Passo em Andamento'
                                          },
                                      },

                                  }}/>
                    </div>
                    <div className="col-3">
                        <Doughnut data={GraficoProcessoPorPassoEmAndamento}

                                  options={{
                                      plugins: {
                                          legend: {
                                              display: true,
                                          },
                                          title: {
                                              display: true,
                                              text: 'Processo/PassoAndamento',
                                          },
                                          subtitle: {
                                              display: true,
                                              text: 'Número de Processo por Passo em Andamento'
                                          },
                                      },

                                  }}/>
                    </div>
                </div>


            </div>
        </>
    )
}